import React, { useState } from 'react';
import styled from 'styled-components';
import { D } from '@dayetopia/library';
import Button from '../components/Button';
import { TextInput } from '../components/TextInput';

export default function GetResults() {
  const [checkboxChecked, setCheckbox] = useState(false);
  const screenWidth = window.screen.width;
  const checkboxWidth = screenWidth > 500 ? '400px' : '90%';
  return (
    <PageContainer>
      <PageContent>
        <H2Styled>Get your contraception recommendation</H2Styled>
        <PStyled>
          Your personalised recommendation is on it's way. Just leave your
          email.{' '}
        </PStyled>
        <TextInputStyled type="text" placeholder="Enter your email address" />
        <CheckboxContainer
          onClick={() => setCheckbox(!checkboxChecked)}
          style={{
            width: checkboxWidth,
          }}
        >
          <D.Checkbox
            checked={checkboxChecked}
            onClick={() => setCheckbox(!checkboxChecked)}
          />
          <PStyled2>
            We're also launching a subscription service soon - where you can buy
            your contraception with us. Tick the box to join the waitlist.
          </PStyled2>
        </CheckboxContainer>
        <ButtonStyled>get my recommendation</ButtonStyled>
      </PageContent>
    </PageContainer>
  );
}

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 50px;
  &:hover {
    cursor: pointer;
  }
  @media only screen and (max-width: 500px) {
    width: 80%;
  }
`;

const ButtonStyled = styled(Button)`
  margin-bottom: 80px;
  margin-top: 30px;
`;
const PStyled2 = styled(D.P)`
  font-size: 16px;
  margin-top: -7px;
  margin-left: 10px;
  @media only screen and (max-width: 500px) {
    width: 80%;
  }
`;

const PStyled = styled(D.P)`
  font-family: BrownStd;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #00391e;
  opacity: 0.6;
  width: 60%;
  margin-top: 0;
  margin-bottom: 30px;
  @media only screen and (max-width: 500px) {
    width: 90%;
  }
`;
const H2Styled = styled(D.H2)`
  font-weight: 500;
  width: 60%;
  font-size: 28px;
  line-height: 40px;
  text-align: center;
  color: #00391e;
  margin-bottom: 10px;
  @media only screen and (max-width: 500px) {
    width: 90%;
  }
`;
const PageContainer = styled.div`
  height: 100vh;
  position: relative;
  background: #eaf5e8;
  @media only screen and (max-width: 500px) {
    height: auto;
  }
`;

const PageContent = styled.div`
  display: flex;
  height: 90%;
  width: 50%;
  margin: auto;
  flex-direction: column;
  animation: fadein 0.8s;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 500px) {
    width: 90%;
  }
`;

const TextInputStyled = styled(TextInput)`
  border: 1.6px solid #00391e;
  width: 400px;
  height: 40px;
  @media only screen and (max-width: 500px) {
    width: 80%;
  }
`;
